import { resetRouter } from '@/router'

export function resetTokenAndClearUser() {
    // 退出登陆 清除用户资料
    localStorage.clear()
    sessionStorage.clear()
    // 重设路由
    resetRouter()
}

export const defaultDocumentTitle = 'meihao-V3'
export function getDocumentTitle(pageTitle) {
    if (pageTitle) return `${defaultDocumentTitle} - ${pageTitle}`
    return `${defaultDocumentTitle}`
}

// 函数防抖
export function debounce(fn, wait) {
    let timeout = null;
    wait = wait || 600;
    return function () {
        let that = this;
        if (timeout !== null) clearTimeout(timeout);
        timeout = setTimeout(() => {
            fn.apply(that);
        }, wait);
    }
}
/*按钮节流*/
export function throttle(func, delay) {
    // 缓存一个定时器
    let timer = null;
    // 这里返回的函数是每次用户实际调用的节流函数
    return function (...args) {
        if (!timer) {
            //判断timer是否有值,如果没有则说明定时器不存在即可继续执行
            timer = setTimeout(() => {
                //关
                func.apply(this, arguments);
                timer = null; //开
            }, delay);
        }
    };
};


