import store from "@/store";
const globalMixins = {
    data() {
        return {
            shop_type: 6,
            dictionary: [],
            globalLoading: false,
            userData: {},
            productDetailData: {},
            // loginTag:false
            businessStatus: "", //门店业态
            mixinPage: {
                pageIndex: 1,
                pageSize: 15,
            },
            pageMax: {
                pageIndex: 1,
                pageSize: 50,
            },
            unlimitPageSize: 9999,
            btnTypeText: "text",
            btnTypePrimary: "primary",
            validateTag: true,
            numberTwoFixed: "",
            baseUrl: "https://meihao.v3.api.meihaocvs.com",
            btnLoading: false,
        };
    },
    methods: {
        fixMoney(num) {
            if (num) {
                return (Number(num) / 1).toFixed(2);
            } else {
                return "--";
            }
        },
        //获取枚举数据
        computeEnumData(key) {
            const enumData = JSON.parse(localStorage.getItem("enumData")) || store.state.enumData;
            return enumData[key];
        },
        //分页重置
        pageInit() {
            this.mixinPage.pageIndex = 1;
            this.mixinPage.pageSize = 15;
            // return { ...this.mixinPage }
        },
        //数字为0时候显示0
        numberToZero(num) {
            if (num == null) {
                return "--";
            }
            if (Number(num) == 0) {
                return 0;
            } else {
                return num ? num : "--";
            }
        },
        //数组是否为空
        isArrayEmpty(data) {
            return data && data.length > 0;
        },
        //枚举转中文
        enumDataToText(enumData, code) {
            if (enumData && enumData.length > 0) {
                const arr = enumData.filter((e) => e.code == code);
                return arr.length > 0 ? arr[0].text : "--";
            } else {
                return "--";
            }
        },
        deepClone(data) {
            return JSON.parse(JSON.stringify(data));
        },
        //searchForm 获取是否通过校验
        getValidateTag(e) {
            this.validateTag = e;
        },
        objectFormReset(obj) {
            Object.keys(obj).forEach((key) => (obj[key] = ""));
            return obj;
        },
        getImg(item) {
            let imgs = [];
            if (item.goods_detail_imgs) {
                imgs = item.goods_detail_imgs?.split(",") || [];
            }
            return imgs[0];
        },

        cutNumber(value, n) {
            return (Math.floor(value * Math.pow(10, n)) / Math.pow(10, n)).toFixed(n);
        },
        download(url) {
            let a = document.createElement("a");
            a.setAttribute("href",  url);
            a.setAttribute("target", "_self");
            // 创建鼠标事件，使用其中的click事件来触发a标签
            let clickEvent = document.createEvent("MouseEvents");
            clickEvent.initEvent("click", true, true);
            a.dispatchEvent(clickEvent);
            // let a=document.createElement('a');
            // a.href=url;
            // document.body.appendChild(a)
            // a.click()//
        },
        //   showLoading(){
        //     const loading = this.$loading({
        //       lock: true,
        //       text: "Loading",
        //       spinner: "el-icon-loading",
        //       background: "rgba(0, 0, 0, 0.7)",
        //     });
        //     return loading
        //   },
        //   removeToken(){
        //     sessionStorage.setItem("token", "")
        //   },
    },
};
export default globalMixins;
